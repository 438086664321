/* eslint-disable import/no-anonymous-default-export */
import { ClientList } from './ClientList';
import { ClientCreate } from './ClientCreate';
import { ClientEdit } from './ClientEdit';
import { ClientShow } from './ClientShow';

export default {
    list: ClientList,
    create: ClientCreate,
    edit: ClientEdit,
    show: ClientShow,
};
