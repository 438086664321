export const contractorStatusEnable = 'enable';
export const contractorStatusDisable = 'disable';
export const contractorStatusTemporary = 'temporary';
export const contractorStatusChoices = [
    { id: contractorStatusEnable, name: '有効' },
    { id: contractorStatusDisable, name: '無効' },
    { id: contractorStatusTemporary, name: '一時' },
];

export const workStatusOpen = 'open';
export const workStatusClosed = 'closed';
export const workStatusChoices = [
    { id: workStatusOpen, name: '未済' },
    { id: workStatusClosed, name: '済' },
];

export const dayOrNightDay = 'day';
export const dayOrNightNight = 'night';
export const dayOrNightChoices = [
    { id: dayOrNightDay, name: '昼' },
    { id: dayOrNightNight, name: '夜' },
];

export const workReportDetailStatusOpen = 'open';
export const workReportDetailStatusClosed = 'closed';
export const workReportDetailStatusChoices = [
    { id: workReportDetailStatusOpen, name: '未済' },
    { id: workReportDetailStatusClosed, name: '済' },
];

export const workReportDetailCheckSlip = 'slip';
export const workReportDetailCheckChoices = [
    { id: workReportDetailCheckSlip, name: '納品書' },
];

export const workUnitStatusEnable = 'enable';
export const workUnitStatusDisable = 'disable';
export const workUnitStatusTemporary = 'temporary';
export const workUnitStatusChoices = [
    { id: workUnitStatusEnable, name: '有効' },
    { id: workUnitStatusDisable, name: '無効' },
    { id: workUnitStatusTemporary, name: '一時' },
];
