import {
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import {
    ArrayInput,
    AutocompleteInput,
    CheckboxGroupInput,
    Create,
    CreateProps,
    DateTimeInput,
    FormDataConsumer,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    SimpleFormIterator,
    TextInput,
    maxValue,
    minValue,
    required,
    useCreateController,
} from 'react-admin';
import {
    CSSTransitionProps,
} from 'react-transition-group/CSSTransition';
import {
    CompactForm,
    RaGrid,
} from 'ra-compact-ui';
import {
    contractorStatusChoices,
    contractorStatusEnable,
    dayOrNightChoices,
    workReportDetailCheckChoices,
    workUnitStatusChoices,
    workUnitStatusEnable,
    workUnitStatusTemporary,
} from '../types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        none: {
            display: 'none',
        },
    }),
);

export const WorkReportCreate = (props: CreateProps) => {
    const classes = useStyles();
    const { record } = useCreateController(props);
    const redirect = () => `/works/${record?.workId}/show`;
    const transitionProps: CSSTransitionProps = {
        classNames: "",
        addEndListener: () => undefined,
    };
    return (
        <Create {...props}>
            <CompactForm layoutComponents={[RaGrid]} redirect={redirect}>
                <RaGrid container spacing={2}>
                    <RaGrid item xs={12}>
                        <RaGrid container spacing={2}>
                            <RaGrid item xs={2}>
                                <TextInput source="workId" label="工事 ID" disabled fullWidth />
                            </RaGrid>
                            <RaGrid item xs={3}>
                                <FormDataConsumer fullWidth>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return (
                                            <ReferenceInput source="workReportTemplateId" label="日報テンプレート" reference="workReportTemplates" perPage={Number.MAX_SAFE_INTEGER} {...rest}>
                                                <AutocompleteInput optionText="name" onSelect={(selectedItem) => {
                                                    if (formData) {
                                                        formData.dayOrNight = selectedItem.dayOrNight;
                                                        formData.details = selectedItem.details;
                                                    }
                                                }} />
                                            </ReferenceInput>
                                        );
                                    }}
                                </FormDataConsumer>
                            </RaGrid>
                        </RaGrid>
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <DateTimeInput source="startedAt" label="開始日時" validate={[required()]} fullWidth />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <DateTimeInput source="finishedAt" label="終了日時" validate={[required()]} fullWidth />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <RadioButtonGroupInput source="dayOrNight" label="昼夜" validate={[required()]} choices={dayOrNightChoices} fullWidth />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <ReferenceInput source="teamId" label="作業班" reference="teams" validate={[required()]} perPage={Number.MAX_SAFE_INTEGER} fullWidth>
                            <AutocompleteInput source="name" />
                        </ReferenceInput>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayInput source="details" label="明細" initialValue={[{}]}>
                            <SimpleFormIterator TransitionProps={transitionProps}>
                                <FormDataConsumer fullWidth>
                                    {({ formData, scopedFormData, getSource }) => {
                                        if (scopedFormData) {
                                            scopedFormData.price = (scopedFormData.quantity && scopedFormData.unitPrice) ? scopedFormData.quantity * scopedFormData.unitPrice : 0;
                                        }
                                        return getSource ? (
                                            <RaGrid container spacing={2}>
                                                <RaGrid item xs={2}>
                                                    <ReferenceInput source={getSource('contractorCategoryId')} label="業種" reference="contractorCategories" validate={[required()]} perPage={Number.MAX_SAFE_INTEGER} fullWidth>
                                                        <AutocompleteInput optionText="name" onSelect={() => {
                                                            if (scopedFormData) {
                                                                scopedFormData.contractorId = null;
                                                                scopedFormData.workUnitId = null;
                                                                scopedFormData.quantity = 0;
                                                                scopedFormData.unitPrice = 0;
                                                                scopedFormData.price = 0;
                                                            }
                                                        }} />
                                                    </ReferenceInput>
                                                </RaGrid>
                                                <RaGrid item xs={2}>
                                                    <ReferenceInput source={getSource('contractorId')} label="発注先" reference="contractors" enableGetChoices={() => scopedFormData?.contractorCategoryId != null} filter={{ contractorCategoryIds: [scopedFormData?.contractorCategoryId] }} validate={[required()]} perPage={Number.MAX_SAFE_INTEGER} fullWidth>
                                                        <AutocompleteInput optionText="name" onSelect={() => {
                                                            if (scopedFormData) {
                                                                scopedFormData.workUnitId = null;
                                                                scopedFormData.quantity = 0;
                                                                scopedFormData.unitPrice = 0;
                                                                scopedFormData.price = 0;
                                                            }
                                                        }} />
                                                    </ReferenceInput>
                                                </RaGrid>
                                                <RaGrid item xs={2}>
                                                    <ReferenceInput source={getSource('workUnitId')} label="項目名" reference="workUnits" enableGetChoices={() => scopedFormData?.contractorId != null && formData.dayOrNight != null} filter={scopedFormData?.workUnitId ? { id: [scopedFormData?.workUnitId] } : { status: workUnitStatusEnable, contractorId: scopedFormData?.contractorId, dayOrNight: formData?.dayOrNight }} validate={[required()]} perPage={Number.MAX_SAFE_INTEGER} fullWidth>
                                                        <AutocompleteInput resettable={true} optionText="name" onSelect={(selectedItem) => {
                                                            if (scopedFormData) {
                                                                scopedFormData.unitPrice = selectedItem.unitPrice;
                                                            }
                                                        }} />
                                                    </ReferenceInput>
                                                </RaGrid>
                                                <RaGrid item xs={1}>
                                                    <NumberInput source={getSource('quantity')} label="数量" validate={[required(), minValue(0), maxValue(Number.MAX_SAFE_INTEGER)]} fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={1}>
                                                    <NumberInput source={getSource('unitPrice')} label="単価" disabled fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={2}>
                                                    <NumberInput source={getSource('price')} label="金額" format={() => scopedFormData ? scopedFormData.price : 0} parse={() => scopedFormData ? scopedFormData.price : 0} disabled fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={2}>
                                                    <CheckboxGroupInput source={getSource('checks')} label="" choices={workReportDetailCheckChoices} fullWidth />
                                                </RaGrid>
                                            </RaGrid>
                                        ) : null;
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayInput source="temporaryDetails" label="一時明細" initialValue={[{}]}>
                            <SimpleFormIterator TransitionProps={transitionProps}>
                                <SelectInput source="contractorStatus" label="発注先状態" choices={contractorStatusChoices} initialValue={contractorStatusEnable} formClassName={classes.none} />
                                <SelectInput source="workUnitStatus" label="項目状態" choices={workUnitStatusChoices} initialValue={workUnitStatusTemporary} formClassName={classes.none} />
                                <FormDataConsumer fullWidth>
                                    {({ scopedFormData, getSource }) => {
                                        if (scopedFormData) {
                                            scopedFormData.price = (scopedFormData.quantity && scopedFormData.unitPrice) ? scopedFormData.quantity * scopedFormData.unitPrice : 0;
                                        }
                                        return getSource ? (
                                            <RaGrid container spacing={2}>
                                                <RaGrid item xs={3}>
                                                    <TextInput source={getSource('contractorName')} label="発注先" validate={[required()]} fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={3}>
                                                    <TextInput source={getSource('workUnitName')} label="項目名" validate={[required()]} fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={1}>
                                                    <NumberInput source={getSource('quantity')} label="数量" validate={[required(), minValue(0), maxValue(Number.MAX_SAFE_INTEGER)]} fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={1}>
                                                    <NumberInput source={getSource('unitPrice')} label="単価" validate={[required(), minValue(0), maxValue(Number.MAX_SAFE_INTEGER)]} fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={2}>
                                                    <NumberInput source={getSource('price')} label="金額" format={() => scopedFormData ? scopedFormData.price : 0} parse={() => scopedFormData ? scopedFormData.price : 0} disabled fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={2}>
                                                    <CheckboxGroupInput source={getSource('checks')} label="" choices={workReportDetailCheckChoices} fullWidth />
                                                </RaGrid>
                                            </RaGrid>
                                        ) : null;
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <FormDataConsumer fullWidth>
                            {({ formData }) => {
                                formData.totalPrice = formData.details.reduce((totalPrice: number, detail?: { price: number }) => totalPrice + (detail ? detail.price : 0), 0);
                                return <NumberInput source="totalPrice" label="合計金額" format={() => formData.totalPrice} parse={() => formData.totalPrice} disabled fullWidth />;
                            }}
                        </FormDataConsumer>
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberInput source="progress" label="進捗率" format={(v: number) => isFinite(v) ? v * 100 : ''} parse={(v: string) => isNaN(parseFloat(v)) ? undefined : parseFloat(v) / 100} validate={[required(), minValue(0), maxValue(1)]} fullWidth />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <TextInput source="comment" label="コメント" fullWidth minRows="5" multiline />
                    </RaGrid>
                </RaGrid>
            </CompactForm>
        </Create>
    );
};
