import {
    AutocompleteArrayInput,
    DateTimeInput,
    Filter,
    FilterProps,
    RadioButtonGroupInput,
    ReferenceArrayInput,
} from 'react-admin';
import { workReportDetailStatusChoices } from '../types';

export const WorkReportDetailListFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <RadioButtonGroupInput
            alwaysOn
            choices={workReportDetailStatusChoices}
            label=""
            source="status"
        />
        <DateTimeInput
            alwaysOn
            label="開始日時 (至)"
            source="startedAtUntil"
        />
        <ReferenceArrayInput
            allowEmpty={false}
            alwaysOn
            label="作業班"
            perPage={Number.MAX_SAFE_INTEGER}
            reference="teams"
            resettable
            source="teamIds"
        >
            <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            allowEmpty={false}
            alwaysOn
            label="発注元"
            perPage={Number.MAX_SAFE_INTEGER}
            reference="clients"
            resettable
            source="clientIds"
        >
            <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            allowEmpty={false}
            alwaysOn
            label="発注先"
            perPage={Number.MAX_SAFE_INTEGER}
            reference="contractors"
            resettable
            source="contractorIds"
        >
            <AutocompleteArrayInput />
        </ReferenceArrayInput>
    </Filter>
);
