import {
    ArrayInput,
    AutocompleteInput,
    CheckboxGroupInput,
    DateInput,
    Edit,
    EditProps,
    FormDataConsumer,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';
import {
    CSSTransitionProps,
} from 'react-transition-group/CSSTransition';
import {
    CompactForm,
    RaGrid,
} from 'ra-compact-ui';
import { Divider } from '../layout';
import { SaveOnlyToolbar } from '../common/SaveOnlyToolbar';
import { workStatusChoices } from '../types';

export const WorkEdit = (props: EditProps) => {
    const transitionProps: CSSTransitionProps = {
        classNames: "",
        addEndListener: () => undefined,
    };
    return (
        <Edit {...props}>
            <CompactForm layoutComponents={[RaGrid]} redirect="show" toolbar={<SaveOnlyToolbar />}>
                <RaGrid container spacing={2}>
                    <RaGrid item xs={1}>
                        <TextInput source="id" label="ID" helperText='' disabled fullWidth />
                    </RaGrid>
                    <RaGrid item xs={1}>
                        <SelectInput source="status" label="状態" helperText='' choices={workStatusChoices} fullWidth />
                    </RaGrid>
                </RaGrid>
                <Divider />
                <RaGrid container spacing={2}>
                    <RaGrid item xs={12}>
                        <RaGrid container spacing={2}>
                            <RaGrid item xs={2}>
                                <DateInput source="orderedOn" label="受注日" fullWidth />
                            </RaGrid>
                            <RaGrid item xs={2}>
                                <FormDataConsumer fullWidth>
                                    {({ formData, scopedFormData, ...rest }) => {
                                        return (
                                            <ReferenceInput source="clientId" label="発注元" reference="clients" perPage={Number.MAX_SAFE_INTEGER} {...rest}>
                                                <AutocompleteInput optionText="name" onSelect={(selectedItem) => {
                                                    formData.clientWorkIds = selectedItem.clientWorkIdTypeIds.map((clientWorkIdTypeId: number) => ({ clientWorkIdTypeId }));
                                                }} />
                                            </ReferenceInput>
                                        );
                                    }}
                                </FormDataConsumer>
                            </RaGrid>
                        </RaGrid>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayInput source="clientWorkIds" label="発注元工事 ID" fullWidth>
                            <SimpleFormIterator TransitionProps={transitionProps}>
                                <FormDataConsumer label="種別" fullWidth>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return getSource ? (
                                            <RaGrid container spacing={2}>
                                                <RaGrid item xs={6}>
                                                    <ReferenceInput source={getSource('clientWorkIdTypeId')} reference="clientWorkIdTypes" enableGetChoices={() => formData.clientId != null} filter={{ clientId: formData?.clientId }} perPage={Number.MAX_SAFE_INTEGER} {...rest}>
                                                        <SelectInput source="id" label="種別" />
                                                    </ReferenceInput>
                                                </RaGrid>
                                                <RaGrid item xs={6}>
                                                    <TextInput source={getSource('clientWorkId')} label="ID" fullWidth />
                                                </RaGrid>
                                            </RaGrid>
                                        ) : null;
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </RaGrid>
                    <RaGrid item xs={6}>
                        <TextInput source="name" label="件名" fullWidth />
                    </RaGrid>
                    <RaGrid item xs={6}>
                        <TextInput source="address" label="住所" fullWidth />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <DateInput source="since" label="工期 (自)" fullWidth />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <DateInput source="until" label="工期 (至)" fullWidth />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <DateInput source="paidOn" label="精算月日" fullWidth />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberInput source="expectedDuration" label="予定日数" fullWidth />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ReferenceArrayInput source="workTypeIds" label="内容" reference="workTypes" fullWidth>
                            <CheckboxGroupInput />
                        </ReferenceArrayInput>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <RaGrid container spacing={2}>
                            <RaGrid item xs={2}>
                                <FormDataConsumer fullWidth>
                                    {({ formData, scopedFormData, ...rest }) => (
                                        <NumberInput source="workload" label="工量" disabled={formData.price && !formData.workload} {...rest} />
                                    )}
                                </FormDataConsumer>
                            </RaGrid>
                            <RaGrid item xs={2}>
                                <FormDataConsumer fullWidth>
                                    {({ formData, scopedFormData, ...rest }) => {
                                        if (formData.workload) {
                                            formData.price = null;
                                        }
                                        return <NumberInput source="price" label="受注金額" disabled={formData.workload > 0} {...rest} />;
                                    }}
                                </FormDataConsumer>
                            </RaGrid>
                            <RaGrid item xs={2}>
                                <FormDataConsumer fullWidth>
                                    {({ formData, scopedFormData, ...rest }) => {
                                        if (formData.workload) {
                                            formData.budget = null;
                                        }
                                        return <NumberInput source="budget" label="実行予算" disabled={formData.workload > 0} {...rest} />;
                                    }}
                                </FormDataConsumer>
                            </RaGrid>
                        </RaGrid>
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberInput source="paidWorkload" label="精算工量" fullWidth />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberInput source="paidPrice" label="精算金額" fullWidth />
                    </RaGrid>
                </RaGrid>
            </CompactForm>
        </Edit >
    );
};
