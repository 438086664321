import {
    random,
} from 'faker';
import { Record } from 'react-admin';
import { dayOrNightChoices, dayOrNightDay, workStatusClosed } from '../types';
import { Db } from './types';

export const generateWorkReports = (db: Db): Record[] => {
    let id = 1;
    return db.works.filter(work => work.status === workStatusClosed).map(work => {
        const team = random.arrayElement(db.teams);
        const progressPerDay = Math.ceil(1 / work.expectedDuration * 100) / 100;
        let totalProgress = 0;
        const workReports = Array.from(Array(work.expectedDuration).keys()).map(days => {
            const date = new Date(work.since.valueOf() + days * 24 * 60 * 60 * 1000);
            const dayOrNight = random.arrayElement(dayOrNightChoices).id;
            const startedAt = new Date(date.getFullYear(), date.getMonth(), date.getDate(), dayOrNight === dayOrNightDay ? 8 : 20, 0, 0, 0);
            const finishedAt = new Date(startedAt.valueOf() + 9 * 60 * 60 * 1000);
            const progress = totalProgress + progressPerDay > 1 ? 1 - totalProgress : progressPerDay;
            const createdAt = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
            totalProgress += progress;
            const workReport = {
                id: id++,
                workId: work.id,
                startedAt: startedAt,
                finishedAt: finishedAt,
                dayOrNight: dayOrNight,
                teamId: team.id,
                comment: '',
                progress: progress,
                clientId: work.clientId,
                createdAt: createdAt,
                updatedAt: createdAt,
            };
            return workReport;
        });
        work.actualDuration = workReports.length;
        work.progress = totalProgress;
        return workReports;
    }).flat();
};
