import {
    Card,
    CardContent,
} from '@material-ui/core';
import {
    Title,
    useRedirect,
} from 'react-admin';

export const Dashboard = () => {
    const redirect = useRedirect();
    redirect('list', 'works');
    return (
        < Card >
            <Title title="SYNK" />
            <CardContent></CardContent>
        </Card >
    );
};
