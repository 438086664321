import {
    Filter,
    FilterProps,
    RadioButtonGroupInput,
    TextInput,
} from 'react-admin';
import { workStatusChoices } from '../types';

export const WorkListFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <RadioButtonGroupInput
            alwaysOn
            choices={workStatusChoices}
            label=""
            source="status"
        />
        <TextInput
            alwaysOn
            label="発注先工事 ID"
            resettable
            source="clientWorkIds.id"
        />
    </Filter>
);
