import {
    Divider as RaDivider,
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import NonRaGrid from './NonRaGrid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            padding: 0,
        },
        divider: {
            margin: theme.spacing(2, 0, 2),
        },
    }),
);

const Divider = () => {
    const classes = useStyles();
    return (
        <NonRaGrid className={classes.grid}>
            <RaDivider className={classes.divider} />
        </NonRaGrid>
    );
};

export default Divider;
