import {
    datatype,
    random,
} from 'faker';
import { Record } from 'react-admin';
import { Db } from './types';

const cities = ['千代田区', '中央区', '港区', '新宿区', '文京区', '台東区', '墨田区', '江東区', '品川区', '目黒区', '大田区', '世田谷区', '渋谷区', '中野区', '杉並区', '豊島区', '北区', '荒川区', '板橋区', '練馬区', '足立区', '葛飾区', '江戸川区', '八王子市', '立川市', '武蔵野市', '三鷹市', '青梅市', '府中市', '昭島市', '調布市', '町田市', '小金井市', '小平市', '日野市', '東村山市', '国分寺市', '国立市', '福生市', '狛江市', '東大和市', '清瀬市', '東久留米市', '武蔵村山市', '多摩市', '稲城市', '羽村市', 'あきる野市', '西東京市', '横浜市', '川崎市', '横須賀市', '鎌倉市', '逗子市', '三浦市', '葉山町', '相模原市', '厚木市', '大和市', '海老名市', '座間市', '綾瀬市', '愛川町', '清川村', '平塚市', '藤沢市', '茅ヶ崎市', '秦野市', '伊勢原市', '寒川町', '大磯町', '二宮町', '小田原市', '南足柄市', '中井町', '大井町', '松田町', '山北町', '開成町', '箱根町', '真鶴町', '湯河原町'];

export const generateClients = (db: Db): Record[] => {
    return Array.from(Array(5).keys()).map(i => {
        const isCity = datatype.boolean();
        const city = random.arrayElement(cities);
        const createdAt = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
        return {
            id: i + 1,
            name: isCity ? city : city.slice(0, -1) + '支社',
            clientWorkIdTypeIds: db.clientWorkIdTypes.filter(type => isCity ? type.id === 3 : type.id < 3).map(type => type.id as number),
            createdAt: createdAt,
            updatedAt: createdAt,
        };
    });
};
