import {
    Create,
    CreateProps,
    TextInput,
    required,
} from 'react-admin';
import {
    CompactForm,
    RaGrid,
} from 'ra-compact-ui';

export const TeamCreate = (props: CreateProps) => {
    return (
        <Create {...props}>
            <CompactForm layoutComponents={[RaGrid]} redirect="show">
                <RaGrid container spacing={2}>
                    <RaGrid item xs={12}>
                        <TextInput source="name" label="名前" validate={[required()]} fullWidth />
                    </RaGrid>
                </RaGrid>
            </CompactForm>
        </Create>
    );
};
