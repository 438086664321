import {
    Divider,
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import {
    ArrayInput,
    AutocompleteInput,
    Edit,
    EditProps,
    FormDataConsumer,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SimpleFormIterator,
    TextInput,
    required,
} from 'react-admin';
import {
    CSSTransitionProps,
} from 'react-transition-group/CSSTransition';
import {
    CompactForm,
    RaGrid,
} from 'ra-compact-ui';
import { SaveOnlyToolbar } from '../common/SaveOnlyToolbar';
import {
    dayOrNightChoices,
    workUnitStatusEnable,
} from '../types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        none: {
            display: 'none',
        },
        divider: {
            margin: theme.spacing(0, 0, 2),
            width: '100%',
        },
    }),
);

export const WorkReportTemplateEdit = (props: EditProps) => {
    const classes = useStyles();
    const transitionProps: CSSTransitionProps = {
        classNames: "",
        addEndListener: () => undefined,
    };
    // TODO Remove disableRemove.
    return (
        <Edit {...props}>
            <CompactForm layoutComponents={[RaGrid]} redirect="show" toolbar={<SaveOnlyToolbar />}>
                <RaGrid container spacing={2}>
                    <RaGrid item xs={1}>
                        <TextInput source="id" label="ID" disabled fullWidth />
                    </RaGrid>
                </RaGrid>
                <Divider className={classes.divider} />
                <RaGrid container spacing={2}>
                    <RaGrid item xs={12}>
                        <TextInput source="name" label="名前" validate={[required()]} fullWidth />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <RadioButtonGroupInput source="dayOrNight" label="昼夜" validate={[required()]} choices={dayOrNightChoices} fullWidth />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayInput source="details" label="明細">
                            <SimpleFormIterator disableReordering TransitionProps={transitionProps}>
                                <NumberInput source="id" label="ID" formClassName={classes.none} />
                                <FormDataConsumer fullWidth>
                                    {({ formData, scopedFormData, getSource }) => {
                                        return getSource ? (
                                            <RaGrid container spacing={2}>
                                                <RaGrid item xs={3}>
                                                    <ReferenceInput source={getSource('contractorCategoryId')} label="業種" reference="contractorCategories" validate={[required()]} perPage={Number.MAX_SAFE_INTEGER} fullWidth>
                                                        <AutocompleteInput optionText="name" onSelect={() => {
                                                            if (scopedFormData) {
                                                                scopedFormData.contractorId = null;
                                                                scopedFormData.workUnitId = null;
                                                            }
                                                        }} />
                                                    </ReferenceInput>
                                                </RaGrid>
                                                <RaGrid item xs={4}>
                                                    <ReferenceInput source={getSource('contractorId')} label="発注先" reference="contractors" enableGetChoices={() => scopedFormData?.contractorCategoryId != null} filter={{ contractorCategoryIds: [scopedFormData?.contractorCategoryId] }} validate={[required()]} perPage={Number.MAX_SAFE_INTEGER} fullWidth>
                                                        <AutocompleteInput optionText="name" onSelect={() => {
                                                            if (scopedFormData) {
                                                                scopedFormData.workUnitId = null;
                                                            }
                                                        }} />
                                                    </ReferenceInput>
                                                </RaGrid>
                                                <RaGrid item xs={5}>
                                                    <ReferenceInput source={getSource('workUnitId')} label="項目名" reference="workUnits" enableGetChoices={() => scopedFormData?.contractorId != null && formData.dayOrNight != null} filter={scopedFormData?.workUnitId ? { id: [scopedFormData?.workUnitId] } : { status: workUnitStatusEnable, contractorId: scopedFormData?.contractorId, dayOrNight: formData?.dayOrNight }} validate={[required()]} perPage={Number.MAX_SAFE_INTEGER} fullWidth>
                                                        <AutocompleteInput resettable={true} optionText="name" />
                                                    </ReferenceInput>
                                                </RaGrid>
                                            </RaGrid>
                                        ) : null;
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </RaGrid>
                </RaGrid>
            </CompactForm>
        </Edit>
    );
};
