import {
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import {
    ArrayField,
    ChipField,
    Datagrid,
    DateField,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    SelectField,
    Show,
    ShowProps,
    SingleFieldList,
    TextField,
} from 'react-admin';
import {
    GridShowLayout,
    RaGrid,
} from 'ra-compact-ui';
import { Divider } from '../layout';
import { dayOrNightChoices } from '../types';
import { ContractorShowActions } from './ContractorShowActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerField: {
        },
        contentField: {
            fontSize: '1.1rem',
        },
        footerField: {
            display: 'inline-block',
            marginRight: theme.spacing(2),
        },
    }),
);

export const ContractorShow = (props: ShowProps) => {
    const classes = useStyles();
    return (
        <Show actions={<ContractorShowActions />} {...props}>
            <GridShowLayout>
                <TextField source="id" label="ID" className={classes.headerField} />
                <Divider />
                <RaGrid container spacing={2}>
                    <RaGrid item xs={12}>
                        <TextField source="name" label="名前" className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ReferenceArrayField source="contractorCategoryIds" label="業種" reference="contractorCategories">
                            <SingleFieldList>
                                <ChipField source="name" size="medium" variant="outlined" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayField source="workUnits" label="工事項目">
                            <Datagrid>
                                <SelectField source="dayOrNight" label="昼夜" choices={dayOrNightChoices} className={classes.contentField} />
                                <TextField source="name" label="名前" sortable={false} className={classes.contentField} />
                                <NumberField source="unitPrice" label="単価" sortable={false} options={{ style: 'currency', currency: 'JPY' }} textAlign="right" className={classes.contentField} />
                            </Datagrid>
                        </ArrayField>
                    </RaGrid>
                </RaGrid>
                <Divider />
                <DateField source="createdAt" label="作成日時" showTime className={classes.footerField} />
                <ReferenceField source="createdBy" label="作成者" reference="users" link="show" className={classes.footerField}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="updatedAt" label="更新日時" showTime className={classes.footerField} />
                <ReferenceField source="updatedBy" label="更新者" reference="users" link="show" className={classes.footerField}>
                    <TextField source="name" />
                </ReferenceField>
            </GridShowLayout>
        </Show>
    );
};
