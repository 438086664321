import {
    Container,
} from '@material-ui/core';

const NonRaGrid = ({ basePath, ...props }: any) => {
    return (
        <Container {...props} />
    );
};

export default NonRaGrid;
