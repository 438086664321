import {
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import {
    ArrayInput,
    CheckboxGroupInput,
    Edit,
    EditProps,
    FormDataConsumer,
    NumberInput,
    ReferenceArrayInput,
    SimpleFormIterator,
    TextInput,
    maxValue,
    minValue,
    required,
} from 'react-admin';
import {
    CSSTransitionProps,
} from 'react-transition-group/CSSTransition';
import {
    CompactForm,
    RaGrid,
} from 'ra-compact-ui';
import { Divider } from '../layout';
import { SaveOnlyToolbar } from '../common/SaveOnlyToolbar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        none: {
            display: 'none',
        },
    }),
);

export const ClientEdit = (props: EditProps) => {
    const classes = useStyles();
    const transitionProps: CSSTransitionProps = {
        classNames: "",
        addEndListener: () => undefined,
    };
    return (
        <Edit {...props}>
            <CompactForm layoutComponents={[RaGrid]} redirect="show" toolbar={<SaveOnlyToolbar />}>
                <RaGrid container spacing={2}>
                    <RaGrid item xs={1}>
                        <TextInput source="id" label="ID" helperText='' disabled fullWidth />
                    </RaGrid>
                </RaGrid>
                <Divider />
                <RaGrid container spacing={2}>
                    <RaGrid item xs={12}>
                        <TextInput source="name" label="名前" validate={[required()]} fullWidth />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ReferenceArrayInput source="clientWorkIdTypeIds" label="発注元工事 ID 種別" reference="clientWorkIdTypes" fullWidth>
                            <CheckboxGroupInput />
                        </ReferenceArrayInput>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayInput source="unitPrices" label="単価">
                            <SimpleFormIterator disableReordering TransitionProps={transitionProps}>
                                <NumberInput source="id" label="ID" formClassName={classes.none} />
                                <FormDataConsumer fullWidth>
                                    {({ getSource }) => {
                                        return getSource ? (
                                            <RaGrid container spacing={2}>
                                                <RaGrid item>
                                                    <ReferenceArrayInput source={getSource('workTypeIds')} label="" reference="workTypes" filter={{ id: 1 }}>
                                                        <CheckboxGroupInput />
                                                    </ReferenceArrayInput>
                                                </RaGrid>
                                                <RaGrid item xs={3}>
                                                    <NumberInput source={getSource('minWorkload')} label="下限工量" validate={[required(), minValue(0), maxValue(999999)]} fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={3}>
                                                    <NumberInput source={getSource('maxWorkload')} label="上限工量" validate={[required(), minValue(0), maxValue(999999)]} fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={3}>
                                                    <NumberInput source={getSource('unitPrice')} label="単価" validate={[required()]} fullWidth />
                                                </RaGrid>
                                            </RaGrid>
                                        ) : null;
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </RaGrid>
                </RaGrid>
            </CompactForm>
        </Edit>
    );
};
