import {
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import {
    ArrayField,
    ChipField,
    Datagrid,
    DateField,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    Show,
    ShowProps,
    SingleFieldList,
    TextField,
} from 'react-admin';
import {
    GridShowLayout,
    RaGrid,
} from 'ra-compact-ui';
import { Divider } from '../layout';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerField: {
        },
        contentField: {
            fontSize: '1.1rem',
        },
        footerField: {
            display: 'inline-block',
            marginRight: theme.spacing(2),
        },
    }),
);

export const ClientShow = (props: ShowProps) => {
    const classes = useStyles();
    return (
        <Show {...props}>
            <GridShowLayout>
                <TextField source="id" label="ID" className={classes.headerField} />
                <Divider />
                <RaGrid container spacing={2}>
                    <RaGrid item xs={12}>
                        <TextField source="name" label="名前" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ReferenceArrayField source="clientWorkIdTypeIds" label="発注元工事 ID 種別" reference="clientWorkIdTypes" fullWidth>
                            <SingleFieldList>
                                <ChipField source="name" size="medium" variant="outlined" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayField source="unitPrices" label="単価">
                            <Datagrid>
                                <ReferenceArrayField source="workTypeIds" label="内容" reference="workTypes" fullWidth>
                                    <SingleFieldList>
                                        <ChipField source="name" size="medium" variant="outlined" />
                                    </SingleFieldList>
                                </ReferenceArrayField>
                                <NumberField source="minWorkload" label="下限工量" sortable={false} textAlign="right" fullWidth className={classes.contentField} />
                                <NumberField source="maxWorkload" label="上限工量" sortable={false} textAlign="right" fullWidth className={classes.contentField} />
                                <NumberField source="unitPrice" label="単価" sortable={false} options={{ style: 'currency', currency: 'JPY' }} textAlign="right" fullWidth className={classes.contentField} />
                            </Datagrid>
                        </ArrayField>
                    </RaGrid>
                </RaGrid>
                <Divider />
                <DateField source="createdAt" label="作成日時" showTime className={classes.footerField} />
                <ReferenceField source="createdBy" label="作成者" reference="users" link="show" className={classes.footerField}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="updatedAt" label="更新日時" showTime className={classes.footerField} />
                <ReferenceField source="updatedBy" label="更新者" reference="users" link="show" className={classes.footerField}>
                    <TextField source="name" />
                </ReferenceField>
            </GridShowLayout>
        </Show>
    );
};
