import {
    ArrayInput,
    CheckboxGroupInput,
    Create,
    CreateProps,
    FormDataConsumer,
    NumberInput,
    ReferenceArrayInput,
    SimpleFormIterator,
    TextInput,
    maxValue,
    minValue,
    required,
} from 'react-admin';
import {
    CSSTransitionProps,
} from 'react-transition-group/CSSTransition';
import {
    CompactForm,
    RaGrid,
} from 'ra-compact-ui';

export const ClientCreate = (props: CreateProps) => {
    const transitionProps: CSSTransitionProps = {
        classNames: "",
        addEndListener: () => undefined,
    };
    return (
        <Create {...props}>
            <CompactForm layoutComponents={[RaGrid]} redirect="show">
                <RaGrid container spacing={2}>
                    <RaGrid item xs={12}>
                        <TextInput source="name" label="名前" validate={[required()]} fullWidth />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ReferenceArrayInput source="clientWorkIdTypeIds" label="発注元工事 ID 種別" reference="clientWorkIdTypes" fullWidth>
                            <CheckboxGroupInput />
                        </ReferenceArrayInput>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayInput source="unitPrices" label="単価" initialValue={[{ workTypeIds: [1], minWorkload: 0, maxWorkload: 699 }, { workTypeIds: [1], minWorkload: 700, maxWorkload: 999999 }, { minWorkload: 0, maxWorkload: 699 }, { minWorkload: 700, maxWorkload: 999999 }]}>
                            <SimpleFormIterator TransitionProps={transitionProps}>
                                <FormDataConsumer fullWidth>
                                    {({ getSource }) => {
                                        return getSource ? (
                                            <RaGrid container spacing={2}>
                                                <RaGrid item>
                                                    <ReferenceArrayInput source={getSource('workTypeIds')} label="" reference="workTypes" filter={{ id: 1 }}>
                                                        <CheckboxGroupInput />
                                                    </ReferenceArrayInput>
                                                </RaGrid>
                                                <RaGrid item xs={3}>
                                                    <NumberInput source={getSource('minWorkload')} label="下限工量" validate={[required(), minValue(0), maxValue(999999)]} fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={3}>
                                                    <NumberInput source={getSource('maxWorkload')} label="上限工量" validate={[required(), minValue(0), maxValue(999999)]} fullWidth />
                                                </RaGrid>
                                                <RaGrid item xs={3}>
                                                    <NumberInput source={getSource('unitPrice')} label="単価" validate={[required()]} fullWidth />
                                                </RaGrid>
                                            </RaGrid>
                                        ) : null;
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </RaGrid>
                </RaGrid>
            </CompactForm>
        </Create>
    );
};
