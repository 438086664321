import { Record } from 'react-admin';
import { Db } from './types';

const names = ['労務', '車両', '舗装材', '材料'];

export const generateContractorCategories = (db: Db): Record[] => {
    return Array.from(Array(names.length).keys()).map(i => {
        const createdAt = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
        return {
            id: i + 1,
            name: names[i],
            createdAt: createdAt,
            updatedAt: createdAt,
        };
    });
};
