import {
    datatype,
    git,
    random,
} from 'faker';
import { Record } from 'react-admin';
import {
    workStatusOpen,
    workStatusClosed,
} from '../types';
import { Db } from './types';
import { randomDate } from './utils';

const symbols = ['△', '◇', '▽', '◎'];
const places = ['市役所', '町役場', '村役場', '官公署', '裁判所', '税務署', '森林管理署', '気象台', '消防署', '保健所', '警察署', '交番', '郵便局', '小学校', '中学校', '高等学校', '大学', '短期大学', '高等専門学校', '病院', '神社', '寺院', '博物館', '図書館', '自衛隊', '工場', '発電所等', '老人ホーム'];
const addresses = [
    {
        state: '東京都',
        cities: ['千代田区', '中央区', '港区', '新宿区', '文京区', '台東区', '墨田区', '江東区', '品川区', '目黒区', '大田区', '世田谷区', '渋谷区', '中野区', '杉並区', '豊島区', '北区', '荒川区', '板橋区', '練馬区', '足立区', '葛飾区', '江戸川区', '八王子市', '立川市', '武蔵野市', '三鷹市', '青梅市', '府中市', '昭島市', '調布市', '町田市', '小金井市', '小平市', '日野市', '東村山市', '国分寺市', '国立市', '福生市', '狛江市', '東大和市', '清瀬市', '東久留米市', '武蔵村山市', '多摩市', '稲城市', '羽村市', 'あきる野市', '西東京市'],
    },
    {
        state: '神奈川県',
        cities: ['横浜市', '川崎市', '横須賀市', '鎌倉市', '逗子市', '三浦市', '葉山町', '相模原市', '厚木市', '大和市', '海老名市', '座間市', '綾瀬市', '愛川町', '清川村', '平塚市', '藤沢市', '茅ヶ崎市', '秦野市', '伊勢原市', '寒川町', '大磯町', '二宮町', '小田原市', '南足柄市', '中井町', '大井町', '松田町', '山北町', '開成町', '箱根町', '真鶴町', '湯河原町'],
    }
];

export const generateWorks = (db: Db): Record[] => {
    return Array.from(Array(100).keys()).map(i => {
        const client = random.arrayElement(db.clients);
        const address = random.arrayElement(addresses);
        const minDate = new Date(Date.now() - (50 - i) * 24 * 60 * 60 * 1000);
        const maxDate = new Date(minDate.valueOf() + (datatype.number(7) + 1) * 24 * 60 * 60 * 1000);
        const since = randomDate(minDate, maxDate);
        const until = randomDate(since, maxDate);
        const workload = client.name.endsWith('支社') ? datatype.number(30000) : null;
        const price = workload ? workload * 200 : datatype.number(50000000);
        const createdAt = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
        return {
            id: i + 1,
            status: until > new Date() ? workStatusOpen : workStatusClosed,
            orderedAt: new Date(since.valueOf() - (30 + datatype.number(30)) * 24 * 60 * 60 * 1000),
            clientId: client.id,
            clientWorkIds: db.clientWorkIdTypes.filter(type => client.name.endsWith('支社') ? type.id < 3 : type.id === 3).map(type => ({ clientWorkIdTypeId: type.id, id: type.id === 2 ? git.shortSha().toUpperCase() : datatype.number({ min: 10000000, max: 99999999 }) })),
            name: random.arrayElements(symbols, datatype.number({ min: 2, max: 5 })).join('') + random.arrayElement(places) + random.arrayElements(symbols, datatype.number({ min: 2, max: 5 })).join('') + '工事',
            address: address.state + random.arrayElement(address.cities) + random.arrayElements(symbols, datatype.number(3)).join('-'),
            since: since,
            until: until,
            expectedDuration: Math.floor((until.valueOf() - since.valueOf()) / 24 / 60 / 60 / 1000) + 1,
            paidOn: until > new Date() ? undefined : new Date(until.valueOf() + (30 + datatype.number(30)) * 24 * 60 * 60 * 1000),
            actualDuration: 0,
            workTypeIds: datatype.boolean() ? [random.arrayElement(db.workTypes).id] : [],
            price: price,
            workload: workload,
            budget: Math.floor(price * (1 + datatype.float({ max: 0.1, precision: 0.01 }))),
            totalPrice: 0,
            paidPrice: 0,
            progress: 0,
            createdAt: createdAt,
            updatedAt: createdAt,
        };
    });
};
