import {
    Edit,
    EditProps,
    TextInput,
    required,
} from 'react-admin';
import {
    CompactForm,
    RaGrid,
} from 'ra-compact-ui';
import { Divider } from '../layout';
import { SaveOnlyToolbar } from '../common/SaveOnlyToolbar';

export const UserEdit = (props: EditProps) => {
    return (
        <Edit {...props}>
            <CompactForm layoutComponents={[RaGrid]} redirect="show" toolbar={<SaveOnlyToolbar />}>
                <RaGrid container spacing={2}>
                    <RaGrid item xs={1}>
                        <TextInput source="id" label="ID" helperText='' disabled fullWidth />
                    </RaGrid>
                </RaGrid>
                <Divider />
                <RaGrid container spacing={2}>
                    <RaGrid item xs={12}>
                        <TextInput source="name" label="名前" validate={[required()]} fullWidth />
                    </RaGrid>
                </RaGrid>
            </CompactForm>
        </Edit>
    );
};
