/* eslint-disable import/no-anonymous-default-export */
import { WorkReportTemplateList } from './WorkReportTemplateList';
import { WorkReportTemplateCreate } from './WorkReportTemplateCreate';
import { WorkReportTemplateEdit } from './WorkReportTemplateEdit';
import { WorkReportTemplateShow } from './WorkReportTemplateShow';

export default {
    list: WorkReportTemplateList,
    create: WorkReportTemplateCreate,
    edit: WorkReportTemplateEdit,
    show: WorkReportTemplateShow,
};
