import {
    ArrayInput,
    AutocompleteInput,
    Create,
    CreateProps,
    FormDataConsumer,
    RadioButtonGroupInput,
    ReferenceInput,
    SimpleFormIterator,
    TextInput,
    required,
} from 'react-admin';
import {
    CSSTransitionProps,
} from 'react-transition-group/CSSTransition';
import {
    CompactForm,
    RaGrid,
} from 'ra-compact-ui';
import {
    dayOrNightChoices,
    workUnitStatusEnable,
} from '../types';

export const WorkReportTemplateCreate = (props: CreateProps) => {
    const transitionProps: CSSTransitionProps = {
        classNames: "",
        addEndListener: () => undefined,
    };
    return (
        <Create {...props}>
            <CompactForm layoutComponents={[RaGrid]} redirect="show">
                <RaGrid container spacing={2}>
                    <RaGrid item xs={12}>
                        <TextInput source="name" label="名前" validate={[required()]} fullWidth />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <RadioButtonGroupInput source="dayOrNight" label="昼夜" validate={[required()]} choices={dayOrNightChoices} fullWidth />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayInput source="details" label="明細" initialValue={[{}]}>
                            <SimpleFormIterator TransitionProps={transitionProps}>
                                <FormDataConsumer fullWidth>
                                    {({ formData, scopedFormData, getSource }) => {
                                        return getSource ? (
                                            <RaGrid container spacing={2}>
                                                <RaGrid item xs={3}>
                                                    <ReferenceInput source={getSource('contractorCategoryId')} label="業種" reference="contractorCategories" validate={[required()]} perPage={Number.MAX_SAFE_INTEGER} fullWidth>
                                                        <AutocompleteInput optionText="name" onSelect={() => {
                                                            if (scopedFormData) {
                                                                scopedFormData.contractorId = null;
                                                                scopedFormData.workUnitId = null;
                                                            }
                                                        }} />
                                                    </ReferenceInput>
                                                </RaGrid>
                                                <RaGrid item xs={4}>
                                                    <ReferenceInput source={getSource('contractorId')} label="発注先" reference="contractors" enableGetChoices={() => scopedFormData?.contractorCategoryId != null} filter={{ contractorCategoryIds: [scopedFormData?.contractorCategoryId] }} validate={[required()]} perPage={Number.MAX_SAFE_INTEGER} fullWidth>
                                                        <AutocompleteInput optionText="name" onSelect={() => {
                                                            if (scopedFormData) {
                                                                scopedFormData.workUnitId = null;
                                                            }
                                                        }} />
                                                    </ReferenceInput>
                                                </RaGrid>
                                                <RaGrid item xs={5}>
                                                    <ReferenceInput source={getSource('workUnitId')} label="項目名" reference="workUnits" enableGetChoices={() => scopedFormData?.contractorId != null && formData.dayOrNight != null} filter={scopedFormData?.workUnitId ? { id: [scopedFormData?.workUnitId] } : { status: workUnitStatusEnable, contractorId: scopedFormData?.contractorId, dayOrNight: formData?.dayOrNight }} validate={[required()]} perPage={Number.MAX_SAFE_INTEGER} fullWidth>
                                                        <AutocompleteInput resettable={true} optionText="name" />
                                                    </ReferenceInput>
                                                </RaGrid>
                                            </RaGrid>
                                        ) : null;
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </RaGrid>
                </RaGrid>
            </CompactForm>
        </Create>
    );
};
