import {
    Dispatch,
    Fragment,
    SetStateAction,
} from 'react';
import {
    BulkActionProps,
    BulkUpdateButton,
    Record,
    RecordMap,
    useListContext,
} from 'react-admin';
import {
    workReportDetailStatusClosed,
    workReportDetailStatusOpen,
} from '../types';

export interface WorkReportDetailListBulkActionProps extends BulkActionProps {
    selectedItems: RecordMap<Record>;
    setSelectedItems: Dispatch<SetStateAction<RecordMap<Record>>>;
}

export const WorkReportDetailListBulkActionButtons = (props: Omit<WorkReportDetailListBulkActionProps, 'children'>) => {
    const { data, loaded, selectedIds } = useListContext();
    if (!loaded) return null;
    props.selectedIds?.forEach(id => {
        if (data[id]) {
            props.selectedItems[id] = { id: 1 }; // XXX This is a workaround to avoid 'TypeError: Cannot convert undefined or null to object'.
            Object.assign(props.selectedItems[id], data[id]);
        }
    });
    props.setSelectedItems(props.selectedItems);
    const closed = props.filterValues?.status === workReportDetailStatusClosed;
    const action = '状態を' + (closed ? '未済' : '済') + 'に変更';
    const price = Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(
        selectedIds.reduce((previousValue: number, currentValue) => {
            return previousValue + props.selectedItems[currentValue]?.price ?? 0;
        }, 0)
    );
    return (
        <Fragment>
            <BulkUpdateButton
                confirmContent={selectedIds.length + ' 件 (' + price + ') の' + action + 'してよろしいですか？'}
                confirmTitle='工事日報明細を更新'
                label={action + ' (' + price + ')'}
                data={{ status: closed ? workReportDetailStatusOpen : workReportDetailStatusClosed }}
                mutationMode='optimistic'
                basePath={props.basePath}
                filterValues={props.filterValues}
                resource={props.resource}
                selectedIds={props.selectedIds}
            />
        </Fragment>
    );
};
