import {
    EditButton,
    ListButton,
    ShowActionsProps,
    TopToolbar,
} from 'react-admin';
import { workReportDetailStatusOpen } from '../types';

export const ContractorShowActions = (props: ShowActionsProps) => (
    <TopToolbar>
        <ListButton
            label="工事日報明細"
            to={{
                pathname: '/workReportDetails',
                search: `?displayedFilters=%7B%7D&filter=%7B"status"%3A"${workReportDetailStatusOpen}"%2C"contractorIds"%3A%5B${props.data?.id}%5D%7D&order=desc&page=1&perPage=25&sort=id`,
            }}
        />
        <EditButton basePath={props.basePath} record={props.data} />
    </TopToolbar>
);
