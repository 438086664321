/* eslint-disable import/no-anonymous-default-export */
import { UserList } from './UserList';
import { UserEdit } from './UserEdit';
import { UserShow } from './UserShow';

export default {
    list: UserList,
    edit: UserEdit,
    show: UserShow,
};
