/* eslint-disable import/no-anonymous-default-export */
import { ContractorList } from './ContractorList';
import { ContractorCreate } from './ContractorCreate';
import { ContractorEdit } from './ContractorEdit';
import { ContractorShow } from './ContractorShow';

export default {
    list: ContractorList,
    create: ContractorCreate,
    edit: ContractorEdit,
    show: ContractorShow,
};
