import { useState } from 'react';
import {
    Datagrid,
    DateField,
    List,
    ListProps,
    NumberField,
    Pagination,
    RecordMap,
    ReferenceField,
    SelectField,
    TextField,
    useGetIdentity,
} from 'react-admin';
import {
    workReportDetailStatusChoices,
    workReportDetailStatusOpen,
} from '../types';
import { WorkReportDetailListBulkActionButtons } from './WorkReportDetailListBulkActionButtons';
import { WorkReportDetailListFilter } from './WorkReportDetailListFilter';

export const WorkReportDetailList = (props: ListProps) => {
    const { identity } = useGetIdentity();
    const [selectedItems, setSelectedItems] = useState({} as RecordMap);
    return identity ? (
        <List
            {...props}
            bulkActionButtons={<WorkReportDetailListBulkActionButtons selectedItems={selectedItems} setSelectedItems={setSelectedItems} />}
            exporter={false}
            filterDefaultValues={{ status: workReportDetailStatusOpen }}
            filters={<WorkReportDetailListFilter />}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            perPage={100}
            sort={{ field: 'id', order: 'desc' }}
        >
            <Datagrid rowClick="toggleSelection">
                <TextField source="id" label="ID" sortable={false} />
                <SelectField source="status" label="状態" choices={workReportDetailStatusChoices} sortable={false} />
                <ReferenceField source="workReportId" label="日報 開始日時" reference="workReports" link="show" sortable={false}>
                    <DateField source="startedAt" showTime options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                </ReferenceField>
                <ReferenceField source="teamId" label="作業班" reference="teams" link="show" sortable={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="clientId" label="発注元" reference="clients" link="show" sortable={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="contractorId" label="発注先" reference="contractors" link="show" sortable={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="workUnitId" label="項目名" reference="workUnits" link="show" sortable={false}>
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="quantity" label="数量" textAlign="right" sortable={false} />
                <NumberField source="unitPrice" label="単価" options={{ style: 'currency', currency: 'JPY' }} textAlign="right" sortable={false} />
                <NumberField source="price" label="金額" options={{ style: 'currency', currency: 'JPY' }} textAlign="right" sortable={false} />
            </Datagrid>
        </List>
    ) : null;
};
