import {
    datatype,
    helpers,
    random,
} from 'faker';
import { Record } from 'react-admin';
import { Db } from './types';

const symbols = ['△', '◇', '▽', '◎'];

export const generateContractors = (db: Db): Record[] => {
    return Array.from(Array(5).keys()).map(i => {
        const createdAt = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
        return {
            id: i + 1,
            name: i === 0 ? '自社' : helpers.shuffle([random.arrayElements(symbols, datatype.number({ min: 2, max: 4 })).join(''), '株式会社']).join(''),
            contractorCategoryIds: i === 0 ? [1] : random.arrayElements(db.contractorCategories, datatype.number({ min: 1, max: 3 })).map(category => category.id as number),
            createdAt: createdAt,
            updatedAt: createdAt,
        };
    });
};
