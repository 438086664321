/* eslint-disable import/no-anonymous-default-export */
import { WorkReportCreate } from './WorkReportCreate';
import { WorkReportEdit } from './WorkReportEdit';
import { WorkReportShow } from './WorkReportShow';

export default {
    create: WorkReportCreate,
    edit: WorkReportEdit,
    show: WorkReportShow,
};
