import {
    SaveButton,
    Toolbar,
    ToolbarProps,
} from 'react-admin';

export const SaveOnlyToolbar = (props: ToolbarProps) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);
