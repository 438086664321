import BuildIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import {
  Admin,
  Resource,
  TranslationMessages,
  defaultTheme,
} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { authRoutes, LoginPage } from 'ra-supabase';
import { createBrowserHistory } from 'history';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import japaneseMessages from './languageJapanese';
import { Dashboard } from './dashboard/Dashboard';
import { Layout } from './layout';
import clients from './clients';
import contractors from './contractors';
import works from './works';
import workReports from './workReports';
import workReportDetails from './workReportDetails';
import workReportTemplates from './workReportTemplates';
import teams from './teams';
import users from './users';

const messages: { [index: string]: TranslationMessages } = {
  ja: japaneseMessages,
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'ja');

const history = createBrowserHistory();

const theme = {
  ...defaultTheme,
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    RaSelectInput: {
      input: {
        minWidth: 0,
      },
    },
  },
};

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    dashboard={Dashboard}
    customRoutes={authRoutes}
    layout={Layout}
    loginPage={LoginPage}
    disableTelemetry
    history={history}
    theme={theme}
  >
    <Resource name="works" options={{ label: '工事' }} icon={BuildIcon} {...works} />
    <Resource name="clients" options={{ label: '発注元' }} icon={BusinessIcon} {...clients} />
    <Resource name="contractors" options={{ label: '発注先' }} icon={BusinessIcon} {...contractors} />
    <Resource name="teams" options={{ label: '作業班' }} icon={GroupIcon} {...teams} />
    <Resource name="users" options={{ label: 'ユーザー' }} icon={PersonIcon} {...users} />
    <Resource name="workReportTemplates" options={{ label: '日報テンプレート' }} icon={DescriptionIcon} {...workReportTemplates} />

    <Resource name="workReports" options={{ label: '工事日報' }} {...workReports} />
    <Resource name="workUnits" options={{ label: '工事項目' }} />
    <Resource name="workReportDetails" options={{ label: '工事日報明細' }} {...workReportDetails} />

    <Resource name="workTypes" options={{ label: '工事内容' }} />
    <Resource name="clientWorkIdTypes" options={{ label: '発注元工事 ID 種別' }} />
    <Resource name="contractorCategories" options={{ label: '業種' }} />
  </Admin >
);

export default App;
