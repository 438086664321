import {
    ChipField,
    Datagrid,
    List,
    ListProps,
    Pagination,
    ReferenceArrayField,
    SingleFieldList,
    TextField,
    useGetIdentity,
} from 'react-admin';
import { contractorStatusEnable } from '../types';

export const ContractorList = (props: ListProps) => {
    const { identity } = useGetIdentity();
    return identity ? (
        <List
            {...props}
            bulkActionButtons={false}
            exporter={false}
            filterDefaultValues={{ status: contractorStatusEnable }}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            perPage={25}
            sort={{ field: 'id', order: 'desc' }}
        >
            <Datagrid rowClick="show">
                <TextField source="id" label="ID" sortable={false} />
                <TextField source="name" label="名前" sortable={false} />
                <ReferenceArrayField source="contractorCategoryIds" label="業種" reference="contractorCategories" sortable={false}>
                    <SingleFieldList>
                        <ChipField source="name" size="small" variant="outlined" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Datagrid>
        </List>
    ) : null;
};
