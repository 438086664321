import {
    Layout as RaLayout,
    LayoutProps,
} from 'react-admin';
import Menu from './Menu';

const Layout = (props: LayoutProps) => {
    return <RaLayout {...props} menu={Menu} />;
};

export default Layout;
