import {
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import {
    ArrayField,
    ChipField,
    CreateButton,
    Datagrid,
    DateField,
    FieldProps,
    FunctionField,
    Labeled,
    NumberField,
    NumberFieldProps,
    Pagination,
    Record,
    ReferenceArrayField,
    ReferenceField,
    ReferenceManyField,
    SelectField,
    Show,
    ShowProps,
    SingleFieldList,
    TextField,
    useRecordContext,
} from 'react-admin';
import {
    GridShowLayout,
    RaGrid,
} from 'ra-compact-ui';
import { Divider, } from '../layout';
import { dayOrNightChoices, workStatusChoices } from '../types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerField: {
            display: 'inline-block',
            marginRight: theme.spacing(2),
        },
        contentField: {
            fontSize: '1.1rem',
        },
        footerField: {
            display: 'inline-block',
            marginRight: theme.spacing(2),
        },
    }),
);

const PriceField = (props: NumberFieldProps) => {
    const record = useRecordContext(props);
    return (
        <Labeled label={(record && record.workload ? '予定' : '') + '受注金額'}>
            <NumberField {...props} />
        </Labeled >
    );
};

const CreateWorkReportButton = (props: FieldProps) => (
    <CreateButton
        label="日報作成"
        to={{
            pathname: '/workReports/create',
            state: { record: { workId: props.record?.id } },
        }}
    ></CreateButton>
);

export const WorkShow = (props: ShowProps) => {
    const classes = useStyles();
    return (
        <Show {...props}>
            <GridShowLayout>
                <TextField source="id" label="ID" className={classes.headerField} />
                <SelectField source="status" label="状態" choices={workStatusChoices} className={classes.headerField} />
                <Divider />
                <RaGrid container spacing={2}>
                    <RaGrid item xs={2}>
                        <DateField source="orderedOn" label="受注日" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={10}>
                        <ReferenceField source="clientId" label="発注元" reference="clients" link="show" fullWidth>
                            <TextField source="name" fullWidth className={classes.contentField} />
                        </ReferenceField>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayField source="clientWorkIds" label="発注元工事 ID">
                            <Datagrid>
                                <ReferenceField source="clientWorkIdTypeId" label="種別" reference="clientWorkIdTypes" fullWidth>
                                    <TextField source="name" label="名前" fullWidth className={classes.contentField} />
                                </ReferenceField>
                                <TextField source="clientWorkId" label="ID" fullWidth className={classes.contentField} />
                            </Datagrid>
                        </ArrayField>
                    </RaGrid>
                    <RaGrid item xs={6}>
                        <TextField source="name" label="件名" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={6}>
                        <TextField source="address" label="住所" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <DateField source="since" label="工期 (自)" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <DateField source="until" label="工期 (至)" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <DateField source="paidOn" label="精算月日" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <TextField source="expectedDuration" label="予定日数" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <TextField source="actualDuration" label="実績日数" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <FunctionField render={(record?: Record) => (record?.expectedDuration ?? 0) - (record?.actualDuration ?? 0)} label="残日数" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ReferenceArrayField source="workTypeIds" label="内容" reference="workTypes" fullWidth>
                            <SingleFieldList>
                                <ChipField source="name" size="medium" variant="outlined" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberField source="workload" label="工量" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <PriceField source="price" options={{ style: 'currency', currency: 'JPY' }} fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberField source="budget" label="実行予算" options={{ style: 'currency', currency: 'JPY' }} fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberField source="totalPrice" label="合計金額" options={{ style: 'currency', currency: 'JPY' }} fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={4}>
                        <FunctionField render={(record?: Record) => Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format((record?.budget ?? 0) - (record?.totalPrice ?? 0))} label="残金額" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberField source="paidWorkload" label="精算工量" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberField source="paidPrice" label="精算金額" options={{ style: 'currency', currency: 'JPY' }} fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ReferenceManyField target="workId" label="日報" reference="workReports" pagination={<Pagination rowsPerPageOptions={[]} />} fullWidth>
                            <Datagrid rowClick="show">
                                <TextField source="id" label="ID" sortable={false} fullWidth className={classes.contentField} />
                                <DateField source="startedAt" label="開始日時" showTime options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }} sortable={false} fullWidth className={classes.contentField} />
                                <DateField source="finishedAt" label="終了日時" showTime options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }} sortable={false} fullWidth className={classes.contentField} />
                                <SelectField source="dayOrNight" label="昼夜" choices={dayOrNightChoices} sortable={false} fullWidth className={classes.contentField} />
                                <ReferenceField source="teamId" label="作業班" reference="teams" link="show" sortable={false} fullWidth>
                                    <TextField source="name" className={classes.contentField} />
                                </ReferenceField>
                                <NumberField source="totalPrice" label="合計金額" options={{ style: 'currency', currency: 'JPY' }} textAlign="right" sortable={false} fullWidth className={classes.contentField} />
                                <NumberField source="progress" label="進捗率" options={{ style: 'percent' }} textAlign="right" sortable={false} fullWidth className={classes.contentField} />
                            </Datagrid>
                        </ReferenceManyField>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <CreateWorkReportButton />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <NumberField source="progress" label="進捗率" options={{ style: 'percent' }} textAlign="right" fullWidth className={classes.contentField} />
                    </RaGrid>
                </RaGrid>
                <Divider />
                <DateField source="createdAt" label="作成日時" showTime className={classes.footerField} />
                <ReferenceField source="createdBy" label="作成者" reference="users" link="show" className={classes.footerField}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="updatedAt" label="更新日時" showTime className={classes.footerField} />
                <ReferenceField source="updatedBy" label="更新者" reference="users" link="show" className={classes.footerField}>
                    <TextField source="name" />
                </ReferenceField>
            </GridShowLayout>
        </Show>
    );
};
