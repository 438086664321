import {
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import {
    ArrayField,
    Datagrid,
    DateField,
    FunctionField,
    NumberField,
    Record,
    ReferenceField,
    SelectField,
    Show,
    ShowProps,
    TextField,
} from 'react-admin';
import {
    GridShowLayout,
    RaGrid,
} from 'ra-compact-ui';
import { Divider } from '../layout';
import {
    dayOrNightChoices,
    workReportDetailCheckSlip,
} from '../types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerField: {
        },
        contentField: {
            fontSize: '1.1rem',
        },
        footerField: {
            display: 'inline-block',
            marginRight: theme.spacing(2),
        },
    }),
);

export const WorkReportShow = (props: ShowProps) => {
    const classes = useStyles();
    return (
        <Show {...props}>
            <GridShowLayout>
                <TextField source="id" label="ID" />
                <Divider />
                <RaGrid container spacing={2}>
                    <RaGrid item xs={2}>
                        <DateField source="startedAt" label="開始日時" showTime options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }} fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <DateField source="finishedAt" label="終了日時" showTime options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }} fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <SelectField source="dayOrNight" label="昼夜" choices={dayOrNightChoices} fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <ReferenceField source="teamId" label="作業班" reference="teams" link="show" fullWidth className={classes.contentField}>
                            <TextField source="name" fullWidth className={classes.contentField} />
                        </ReferenceField>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayField source="details" label="明細">
                            <Datagrid>
                                <ReferenceField source="contractorId" label="発注先" reference="contractors" link="show" fullWidth>
                                    <TextField source="name" fullWidth className={classes.contentField} />
                                </ReferenceField>
                                <ReferenceField source="workUnitId" label="項目名" reference="workUnits" link="show" fullWidth>
                                    <TextField source="name" fullWidth className={classes.contentField} />
                                </ReferenceField>
                                <NumberField source="quantity" label="数量" textAlign="right" fullWidth className={classes.contentField} />
                                <NumberField source="unitPrice" label="単価" options={{ style: 'currency', currency: 'JPY' }} textAlign="right" fullWidth className={classes.contentField} />
                                <NumberField source="price" label="金額" options={{ style: 'currency', currency: 'JPY' }} textAlign="right" fullWidth className={classes.contentField} />
                                <FunctionField label="納品書" render={(record?: Record) => {
                                    if (!record) return '';
                                    const checks: string[] = record.checks ?? [];
                                    return checks.some(check => check === workReportDetailCheckSlip) ? <DoneIcon /> : <ClearIcon />;
                                }} fullWidth className={classes.contentField} />
                            </Datagrid>
                        </ArrayField>
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <ArrayField source="temporaryDetails" label="一時明細">
                            <Datagrid>
                                <TextField source="contractorName" label="発注先" fullWidth className={classes.contentField} />
                                <TextField source="workUnitName" label="項目名" fullWidth className={classes.contentField} />
                                <NumberField source="quantity" label="数量" textAlign="right" fullWidth className={classes.contentField} />
                                <NumberField source="unitPrice" label="単価" options={{ style: 'currency', currency: 'JPY' }} textAlign="right" fullWidth className={classes.contentField} />
                                <NumberField source="price" label="金額" options={{ style: 'currency', currency: 'JPY' }} textAlign="right" fullWidth className={classes.contentField} />
                                <FunctionField label="納品書" render={(record?: Record) => {
                                    if (!record) return '';
                                    const checks: string[] = record.checks ?? [];
                                    return checks.some(check => check === workReportDetailCheckSlip) ? <DoneIcon /> : <ClearIcon />;
                                }} fullWidth className={classes.contentField} />
                            </Datagrid>
                        </ArrayField>
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberField source="totalPrice" label="合計金額" options={{ style: 'currency', currency: 'JPY' }} textAlign="right" fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={2}>
                        <NumberField source="progress" label="進捗率" options={{ style: 'percent' }} fullWidth className={classes.contentField} />
                    </RaGrid>
                    <RaGrid item xs={12}>
                        <TextField source="comment" label="コメント" fullWidth className={classes.contentField} />
                    </RaGrid>
                </RaGrid>
                <Divider />
                <DateField source="createdAt" label="作成日時" showTime className={classes.footerField} />
                <ReferenceField source="createdBy" label="作成者" reference="users" link="show" className={classes.footerField}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="updatedAt" label="更新日時" showTime className={classes.footerField} />
                <ReferenceField source="updatedBy" label="更新者" reference="users" link="show" className={classes.footerField}>
                    <TextField source="name" />
                </ReferenceField>
            </GridShowLayout>
        </Show>
    );
};
