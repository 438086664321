/* eslint-disable import/no-anonymous-default-export */
import { Db } from './types';
import { generateClientWorkIdTypes } from './clientWorkIdTypes';
import { generateClients } from './clients';
import { generateContractorCategories } from './contractorCategories';
import { generateContractors } from './contractors';
import { generateWorkUnits } from './workUnits';
import { generateUsers } from './users';
import { generateTeams } from './teams';
import { generateWorkTypes } from './workTypes';
import { generateWorks } from './works';
import { generateWorkReports } from './workReports';
import { generateWorkReportDetails } from './workReportDetails';

export default (): Db => {
    const db = {} as Db;
    db.clientWorkIdTypes = generateClientWorkIdTypes(db);
    db.clients = generateClients(db);
    db.contractorCategories = generateContractorCategories(db);
    db.contractors = generateContractors(db);
    db.workUnits = generateWorkUnits(db);
    db.users = generateUsers(db);
    db.teams = generateTeams(db);
    db.workTypes = generateWorkTypes(db);
    db.works = generateWorks(db);
    db.workReports = generateWorkReports(db);
    db.workReportDetails = generateWorkReportDetails(db);
    return db;
};
