/* eslint-disable import/no-anonymous-default-export */
import { WorkList } from './WorkList';
import { WorkCreate } from './WorkCreate';
import { WorkEdit } from './WorkEdit';
import { WorkShow } from './WorkShow';

export default {
    list: WorkList,
    create: WorkCreate,
    edit: WorkEdit,
    show: WorkShow,
};
