import fakerestDataProvider from 'ra-data-fakerest';
import jsonServerProvider from 'ra-data-json-server';
import {
    CreateParams,
    CreateResult,
    DataProvider,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    GetOneResult,
    Record,
    UpdateManyParams,
    UpdateParams,
    UpdateResult,
    fetchUtils,
} from 'react-admin';
import generateData from './dataGenerator';
import { supabase } from './supabase';

const baseDataProvider: DataProvider = fakerestDataProvider(generateData(), true);
const extendedDataProvider: DataProvider = {
    ...baseDataProvider,
    getOne: async <RecordType extends Record = Record>(resource: string, params: GetOneParams) => {
        if (resource === 'workReports') {
            const workReportDetails = await baseDataProvider.getList('workReportDetails', {
                pagination: { page: 1, perPage: 25 },
                sort: { field: 'id', order: 'desc' },
                filter: { workReportId: params.id },
            } as GetListParams);
            const workReports = await baseDataProvider.getOne(resource, params);
            workReports.data.details = workReportDetails.data.map(detail => {
                return {
                    id: detail.id,
                    contractorCategoryId: detail.contractorCategoryId,
                    contractorId: detail.contractorId,
                    workUnitId: detail.workUnitId,
                    workload: detail.workload,
                    unitPrice: detail.unitPrice,
                    price: detail.price,
                };
            });
            return workReports as GetOneResult<RecordType>;
        }
        return await baseDataProvider.getOne(resource, params);
    },
    create: async <RecordType extends Record = Record>(resource: string, params: CreateParams) => {
        if (resource === 'workReports') {
            const work = await baseDataProvider.getOne('works', { id: params.data.workId });
            await baseDataProvider.update('works', {
                id: work.data.id,
                data: {
                    actualDuration: (work.data.actualDuration ?? 0) + 1,
                    totalPrice: (work.data.totalPrice ?? 0) + params.data.totalPrice,
                    progress: (work.data.progress ?? 0) + params.data.progress,
                    updatedAt: new Date(),
                },
            } as UpdateParams);
            const details = params.data.details;
            delete params.data.details;
            const workReport = await baseDataProvider.create(resource, {
                ...params,
                data: {
                    ...params.data,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                },
            });
            for (const detail of details) {
                baseDataProvider.create('workReportDetails', {
                    data: {
                        workReportId: workReport.data.id,
                        contractorCategoryId: detail.contractorCategoryId,
                        contractorId: detail.contractorId,
                        workUnitId: detail.workUnitId,
                        workload: detail.workload,
                        unitPrice: detail.unitPrice,
                        price: detail.price,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                });
            }
            return workReport as CreateResult<RecordType>;
        }
        return await baseDataProvider.create(resource, {
            ...params,
            data: {
                ...params.data,
                createdAt: new Date(),
                updatedAt: new Date(),
            },
        });
    },
    update: async <RecordType extends Record = Record>(resource: string, params: UpdateParams) => {
        if (resource === 'workReports') {
            const work = await baseDataProvider.getOne('works', { id: params.data.workId });
            baseDataProvider.update('works', {
                id: work.data.id,
                data: {
                    totalPrice: work.data.totalPrice - params.previousData.totalPrice + params.data.totalPrice,
                    progress: work.data.progress - params.previousData.progress + params.data.progress,
                    updatedAt: new Date(),
                },
            } as UpdateParams);
            const details = params.data.details;
            delete params.data.details;
            const workReport = await baseDataProvider.update(resource, {
                ...params,
                data: {
                    ...params.data,
                    updatedAt: new Date(),
                },
            });
            for (const detail of details) {
                if (detail.id) {
                    baseDataProvider.update('workReportDetails', {
                        id: detail.id,
                        data: {
                            id: detail.id,
                            workReportId: workReport.data.id,
                            contractorCategoryId: detail.contractorCategoryId,
                            contractorId: detail.contractorId,
                            workUnitId: detail.workUnitId,
                            workload: detail.workload,
                            unitPrice: detail.unitPrice,
                            price: detail.price,
                            updatedAt: new Date(),
                        },
                        previousData: {
                            id: detail.id,
                        },
                    });
                } else {
                    baseDataProvider.create('workReportDetails', {
                        data: {
                            workReportId: workReport.data.id,
                            contractorCategoryId: detail.contractorCategoryId,
                            contractorId: detail.contractorId,
                            workUnitId: detail.workUnitId,
                            workload: detail.workload,
                            unitPrice: detail.unitPrice,
                            price: detail.price,
                            createdAt: new Date(),
                            updatedAt: new Date(),
                        }
                    });
                }
            }
            return workReport as UpdateResult<RecordType>;
        }
        return await baseDataProvider.update(resource, {
            ...params,
            data: {
                ...params.data,
                updatedAt: new Date(),
            },
        });
    },
};

const httpClient = supabase ? (url: any, options: fetchUtils.Options = {}) => {
    const session = supabase?.auth.session();
    options.user = {
        authenticated: session != null,
        token: session?.access_token,
    };
    return fetchUtils.fetchJson(url, options);
} : undefined;

const dataProvider: DataProvider = process.env.REACT_APP_DATA_PROVIDER_URL ? jsonServerProvider(process.env.REACT_APP_DATA_PROVIDER_URL, httpClient) : new Proxy(extendedDataProvider, {
    get: (target, name: string) => (
        resource: string,
        params: GetListParams | GetOneParams | GetManyParams | GetManyReferenceParams | UpdateParams | UpdateManyParams | CreateParams | DeleteParams | DeleteManyParams,
    ) =>
        new Promise(resolve =>
            setTimeout(
                () => resolve(extendedDataProvider[name](resource, params)),
                300
            )
        ),
});

export default dataProvider;
