import { Record } from 'react-admin';
import { Db } from './types';

export const generateTeams = (db: Db): Record[] => {
    return db.users.map(u => ({
        id: u.id,
        name: u.name,
        createdAt: u.createdAt,
        updatedAt: u.updatedAt,
    }));
};
