import {
    Divider,
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import GroupIcon from '@material-ui/icons/Group';
import Notes from '@material-ui/icons/Notes';
import PersonIcon from '@material-ui/icons/Person';
import {
    Menu as RaMenu,
    MenuItemLink,
    MenuProps
} from 'react-admin';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            margin: theme.spacing(1),
        },
    }),
);

const Menu = (props: MenuProps) => {
    const classes = useStyles();
    return (
        <RaMenu {...props}>
            <MenuItemLink to="/works" primaryText="工事" leftIcon={<BuildIcon />} />
            <MenuItemLink to="/workReportDetails" primaryText="工事日報明細" leftIcon={<Notes />} />
            <Divider className={classes.divider} />
            <MenuItemLink to="/clients" primaryText="発注元" leftIcon={<BusinessIcon />} />
            <MenuItemLink to="/contractors" primaryText="発注先" leftIcon={<BusinessIcon />} />
            <MenuItemLink to="/teams" primaryText="作業班" leftIcon={<GroupIcon />} />
            <MenuItemLink to="/users" primaryText="ユーザー" leftIcon={<PersonIcon />} />
            <MenuItemLink to="/workReportTemplates" primaryText="日報テンプレート" leftIcon={<DescriptionIcon />} />
        </RaMenu>
    );
};

export default Menu;
