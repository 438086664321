import {
    datatype,
    random,
} from 'faker';
import { Record } from 'react-admin';
import {
    workReportDetailStatusOpen,
    workReportDetailStatusClosed,
} from '../types';
import { Db } from './types';

export const generateWorkReportDetails = (db: Db): Record[] => {
    let id = 1;
    return db.workReports.map(workReport => {
        let totalPrice = 0;
        const workDetails = random.arrayElements(db.contractors, datatype.number({ min: 1, max: 3 })).map(contractor => {
            return random.arrayElements(db.workUnits.filter(workUnit => workUnit.contractorId === contractor.id), datatype.number({ min: 1, max: 3 })).map(workUnit => {
                const workload = datatype.number({ min: 1, max: 3 });
                const price = workload * workUnit.unitPrice;
                const monthAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
                totalPrice += price;
                return {
                    id: id++,
                    status: workReport.startedAt > monthAgo ? workReportDetailStatusOpen : workReportDetailStatusClosed,
                    workReportId: workReport.id,
                    contractorCategoryId: random.arrayElement(contractor.contractorCategoryIds),
                    contractorId: contractor.id,
                    workUnitId: workUnit.id,
                    workload,
                    unitPrice: workUnit.unitPrice,
                    price,
                    clientId: workReport.clientId,
                    teamId: workReport.teamId,
                };
            });
        }).flat();
        workReport.totalPrice = totalPrice;
        const work = db.works.find(work => work.id === workReport.workId);
        if (work) {
            work.totalPrice += totalPrice;
            work.paidPrice += totalPrice;
        }
        return workDetails;
    }).flat();
};
