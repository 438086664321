import {
    ArrayField,
    ChipField,
    Datagrid,
    DateField,
    List,
    ListProps,
    NumberField,
    Pagination,
    SelectField,
    SingleFieldList,
    TextField,
    useGetIdentity,
} from 'react-admin';
import { workStatusOpen, workStatusChoices } from '../types';
import { WorkListFilter } from './WorkListFilter';

export const WorkList = (props: ListProps) => {
    const { identity } = useGetIdentity();
    return identity ? (
        <List
            {...props}
            bulkActionButtons={false}
            exporter={false}
            filterDefaultValues={{ status: workStatusOpen }}
            filters={<WorkListFilter />}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            perPage={25}
            sort={{ field: 'id', order: 'desc' }}
        >
            <Datagrid rowClick="show">
                <TextField source="id" label="ID" sortable={false} />
                <SelectField source="status" label="状態" choices={workStatusChoices} sortable={false} />
                <ArrayField source="clientWorkIds" label="発注元工事 ID" sortable={false} >
                    <SingleFieldList linkType={false}>
                        <ChipField source="clientWorkId" size="small" variant="outlined" />
                    </SingleFieldList>
                </ArrayField>
                <TextField source="name" label="件名" sortable={false} />
                <DateField source="since" label="工期 (自)" sortable={false} />
                <DateField source="until" label="工期 (至)" sortable={false} />
                <TextField source="expectedDuration" label="予定日数" sortable={false} />
                <NumberField source="workload" label="工量" sortable={false} textAlign="right" />
                <NumberField source="price" label="金額" options={{ style: 'currency', currency: 'JPY' }} sortable={false} textAlign="right" />
            </Datagrid>
        </List>
    ) : null;
};
