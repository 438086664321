import {
    Datagrid,
    List,
    ListProps,
    Pagination,
    TextField,
    useGetIdentity,
} from 'react-admin';

export const UserList = (props: ListProps) => {
    const { identity } = useGetIdentity();
    return identity ? (
        <List
            {...props}
            bulkActionButtons={false}
            exporter={false}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            perPage={25}
            sort={{ field: 'id', order: 'desc' }}
        >
            <Datagrid rowClick="show">
                <TextField source="id" label="ID" sortable={false} />
                <TextField source="name" label="名前" sortable={false} />
            </Datagrid>
        </List>
    ) : null;
};
