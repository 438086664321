import {
    datatype,
    random,
} from 'faker';
import { Record } from 'react-admin';
import { Db } from './types';

export const generateWorkUnits = (db: Db): Record[] => {
    let id = 1;
    return db.contractors.map(contractor => {
        const createdAt = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
        return random.arrayElements([
            { contractorCategoryId: 1, name: '作業員', unitPrice: datatype.number({ min: 10000, max: 15000 }) },
            { contractorCategoryId: 1, name: '作業責任者', unitPrice: datatype.number({ min: 15000, max: 20000 }) },
            { contractorCategoryId: 1, name: '残業', unitPrice: datatype.number({ min: 1000, max: 3000 }) },
            { contractorCategoryId: 2, name: 'ダンプ', unitPrice: datatype.number({ min: 3000, max: 5000 }) },
            { contractorCategoryId: 3, name: '残土', unitPrice: datatype.number({ min: 5000, max: 10000 }) },
            { contractorCategoryId: 4, name: 'セメント', unitPrice: datatype.number({ min: 500, max: 2000 }) },
            { contractorCategoryId: 3, name: '黒土', unitPrice: datatype.number({ min: 500, max: 2000 }) },
            { contractorCategoryId: 3, name: '山砂', unitPrice: datatype.number({ min: 1000, max: 3000 }) },
            { contractorCategoryId: 3, name: '川砂', unitPrice: datatype.number({ min: 500, max: 2000 }) },
            { contractorCategoryId: 3, name: '砕石', unitPrice: datatype.number({ min: 1000, max: 3000 }) },
            { contractorCategoryId: 2, name: '高所作業車', unitPrice: datatype.number({ min: 10000, max: 20000 }) },
            { contractorCategoryId: 4, name: '乳剤', unitPrice: datatype.number({ min: 5000, max: 10000 }) },
        ].filter(unit => contractor.contractorCategoryIds.includes(unit.contractorCategoryId)), datatype.number({ min: 1, max: 10 })).map((unit, i) => {
            return Object.assign({
                id: id++,
                contractorId: contractor.id,
                createdAt: createdAt,
                updatedAt: createdAt,
            }, unit);
        });
    }).flat();
};
